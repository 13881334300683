import React from "react"
import { PageLayout } from "../components/layout"
import { graphql, Link as GatsbyLink } from "gatsby"
import Seo from "../components/seo"
import BlogStat from "../components/blogStat"

const TagsPage = ({ pageContext: { tag }, data: { allMdx } }) => {
  const posts = allMdx.edges
  return (
    <PageLayout>
      <Seo title={tag} />
      <div className="flex flex-col space-y-5 main">
        <p className="text-title">{`标签『 ${tag} 』`}</p>
        {
          posts.map(({ node }) => (
            <div className="card">
              <GatsbyLink to={node.fields.slug} className="flex flex-col space-y-4">
                <p className="text-xl font-bold hover:opacity-75">
                  {node.frontmatter.title}
                </p>
                <p >{node.excerpt}</p>
                <BlogStat date={node.frontmatter.date} />
              </GatsbyLink>
            </div>
          ))
        }
      </div>
    </PageLayout>
  )
}

export default TagsPage

export const pageQuery = graphql`
query($tag: String) {
  allMdx(filter: {frontmatter: {tags: {in: [$tag]}}}) {
    totalCount
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
        }
      }
    }
  }
}

`